@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  @apply cursor-pointer inline-block bg-blue-500 hover:bg-blue-700 text-blue-50 hover:text-blue-50 font-bold p-2 rounded-lg text-center;
}
.btn-outlined {
  @apply cursor-pointer inline-block text-blue-500 border-blue-400 hover:bg-blue-100 font-bold border p-2 rounded-lg text-center;
}
select,
.input-text {
  @apply appearance-none border w-full py-2 px-3 text-gray-700 leading-tight border-gray-300 rounded-lg hover:border-gray-400 focus:border-blue-400;
}
.badge-rounded-full {
  @apply inline-flex justify-center items-center rounded-full text-white font-bold mr-1 bg-blue-400 w-6 h-6;
}

.tooltip {
  @apply p-2 text-xs text-gray-700 bg-white shadow rounded z-30;
}
.tooltip-trigger {
  @apply relative;
}

.tooltip {
  opacity: 0;
  visibility: hidden;
  width: 100px;
  margin-left: -50px;
  position: absolute;
  top: 130%;
  font-size: 10px;
  z-index: 999;
}

.tooltip-trigger:hover .tooltip {
  opacity: 1;
  visibility: visible;
}
